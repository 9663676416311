<template>
  <section id="legal-disclosures-section-id">
    <h2>Legal Disclosures</h2>
    <p>
      1) Investments are not guaranteed to rise in value,risk of loss of
      principal is possible.
    </p>
    <p>
      2) We offer several different investment advisory programs that have
      different features and support different types of investment strategies.
      Each of these Programs are described in this disclosure. Each Program also
      has a disclosure document on file with the U.S Securities and Exchange
      Commission that is known as Form ADV Part 2 or the ADV Brochure.
    </p>
    <p>
      3) Advisory Program- Inconnection with your decision to transition to
      advisory program, you acknowledge that you have considered each of the
      below relevant factors and confirm the following:
    </p>
    <p>3a) Informed Consent to Establish an Advisory Account</p>
    <p>
      3b) Advisory Fees- Unlike a brokerage account in which clients pay
      transaction-based fees or a commission for each trade, in your advisory
      account you will pay an annual advisory fee that is calculated based on a
      percentage of the value of your assets under management in your advisory
      account.
    </p>
    <p>
      4) Trading Authority- Unlike a brokerage account where your Financial
      Advisor is required to obtain your approval for each trade, you understand
      that depending on the specific Program you choose, you may delegate
      investment authority to R&I Management.
    </p>
    <p>
      5) Investment Restrictions- You may request reasonable restrictions on the
      management of your account such as certain securities or categories of
      securities not to be purchased for your account. Depending on the Program,
      either R&I Management or the Manager will determine, in its sole
      discretion, how to implement such restrictions. Any restriction you impose
      will not apply to the management of the underlying securities in any
      mutual fund, closed-ended fund, variable annuity, or ETF included in your
      account. Different Programs may address such restrictions differently and
      may not be able to accommodate the same restrictions. Such restrictions
      may negatively impact the performance of your account. Please consult with
      the Manager.
    </p>
  </section>
</template>

<script setup></script>

<style lang="scss">
@import "./LegalDisclosuresSection.scss";
</style>
