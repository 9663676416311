<template>
  <section id="articles-title-section-id">
    <h1 class="team-header animate__animated animate__fadeInUp">
      Articles and Guides
    </h1>
    <div>
      <button>Insert Articles Structure html</button>
    </div>
  </section>
</template>

<script setup></script>

<style lang="scss">
@import "./ArticlesTitleSection.scss";
</style>
