<template>
  <section id="team-intro-section-id">
    <h1 class="team-header animate__animated animate__fadeInUp">
      R&I Management Team
    </h1>
    <p class="team-description animate__animated animate__fadeInUp">
      Combining strong expertise in real estate and financial services, our team
      is passionate about financial growth and development.
    </p>
  </section>
</template>

<script setup></script>
<style lang="scss">
@import "./TeamIntroSection.scss";
</style>
