import { ref } from "vue";
import { defineStore } from "pinia";

export const useLevelsStore = defineStore("levels", () => {
  const levelCards = ref([
    {
      cardHeader: "Novice:",
      cardBody: `Remove the complexity from financial jargon and learn 
    from experts how to manage your wealth. 
    Whether you’re just starting to build a portfolio 
    or seeking advice on how to manage existing assets, 
    we’ve got you covered.`,
      ImgSrc:
        "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=91,h=91,fit=crop,trim=0;0;0;0/m5KrbjM6KzfVVwPD/novice-level-icon-AMqnjnXz3ahJOOVX.png",
      cardDetails: [
        "Managing New Assets & Investments",
        "Increased Financial Literacy",
        "Diversified Portfolio Wealth Advisory",
      ],
    },
    {
      cardHeader: "Pioneer:",
      cardBody: `Strengthen your existing portfolio with strategic insights guided by our seasoned professionals. We value partnership and transparency to help you increase financial literacy and prepare for long-term wealth preservation.`,
      ImgSrc:
        "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=91,h=91,fit=crop,trim=0;0;0;0/m5KrbjM6KzfVVwPD/novice-level-icon-AMqnjnXz3ahJOOVX.png",
      cardDetails: [
        "Managing New and Existing Assets & Investments",
        "Increased Financial Literacy",
        "Diversified Portfolio Wealth Advisory",
      ],
    },
    {
      cardHeader: "Expert:",
      cardBody: `Streamline your financial decisions with expert guidance. Whether you’re planning for retirement or seeking to preserve your legacy, our X+ years of experience will guide you towards financial security.`,
      cardDetails: [
        "Wealth Preservation",
        "Generational Transfer",
        "Additional Market Opportunity",
      ],
      ImgSrc:
        "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=91,h=91,fit=crop,trim=0;0;0;0/m5KrbjM6KzfVVwPD/novice-level-icon-AMqnjnXz3ahJOOVX.png",
    },
  ]);

  return {
    levelCards,
  };
});

export default { useLevelsStore };
