<template>
  <header id="head-section-id">
    <div class="header-container">
      <img
        src="../../../public/assets/rimanagement.png"
        alt=""
        class="logo-style"
      />

      <nav class="header-right-side">
        <ul>
          <li>
            <router-link
              to="/"
              :class="{
                'header-button-style': true,
                'active-route': isHomeRoute,
              }"
            >
              R&I Management
            </router-link>
          </li>
          <li>
            <router-link
              to="/leadership"
              class="header-button-style"
              :class="{ 'active-route': !isHomeRoute && !isArticlesPage }"
            >
              Leadership
            </router-link>
          </li>
          <li>
            <router-link
              to="/articles"
              class="header-button-style"
              :class="{ 'active-route': !isHomeRoute && !isLeadershipPage }"
            >
              Articles and Guides
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const isHomeRoute = ref(false);
const isLeadershipPage = ref(false);
const isArticlesPage = ref(false);

watch(
  route,
  () => {
    isHomeRoute.value = route.path === "/";
    isLeadershipPage.value = route.path === "/leadership";
    isArticlesPage.value = route.path === "/articles";
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@import "./HeaderSection.scss";
</style>
