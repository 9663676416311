import { ref } from "vue";
import { defineStore } from "pinia";
import axios from "axios";

export const useSendEmailsStore = defineStore("sendEmails", () => {
  const clientEmail = ref("");
  const clientFirstName = ref("");
  const clientLastName = ref("");
  const emailMessage = ref("");
  const receiverEmail = ref("ghung@rimanagement.co");

  const sendEmail = async () => {
    const data = {
      personalizations: [
        {
          to: [{ email: clientEmail.value }],
          subject: "Email Inquiry from RIManagement Website",
        },
      ],
      from: { email: "mchow@rimanagement.co" },
      content: [
        {
          type: "text/plain",
          value: "and easy to do anywhere, even with Node.js",
        },
        {
          type: "text/html",
          value:
            "<strong><span>" +
            clientFirstName.value +
            "</span><span> " +
            clientLastName.value +
            ": </span>" +
            emailMessage.value +
            "</strong>",
        },
      ],
    };

    try {
      const response = await axios.post(
        "http://localhost:3000/send-email",
        data
      );
      console.log("Email sent", response);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return {
    clientEmail,
    clientFirstName,
    clientLastName,
    emailMessage,
    receiverEmail,
    sendEmail,
  };
});
