<template>
  <section id="info-summary-id">
    <div class="info-summary-container">
      <div class="info-heading-container">
        <span class="sub-heading-1"
          ><strong>Investments &amp; Property Management</strong></span
        >
        <span class="sub-heading-2"><strong>R&I Management </strong></span>

        <span class="sub-body-1">
          Secure your legacy with strategic wealth management. We’re by your
          side, every step of the way—from investments to retirement. We’re
          committed to your lifelong financial security.
        </span>
        <button class="start-today-btn">Start Today</button>
      </div>

      <div class="info-summary-img-container">
        <img
          class="info-summary-img animate__animated animate__fadeInUp"
          alt=""
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=606,h=400,fit=crop/m5KrbjM6KzfVVwPD/hero-stock-image-dJoJxJGOxlseXaGp.png"
        />
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "./IntroSummarySection.scss";
</style>
