import { ref } from "vue";
import { defineStore } from "pinia";

export const useStaffsStore = defineStore("staffs", () => {
  const staffCards = ref([
    {
      imgString:
        "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=297,h=264,fit=crop,trim=64.11363636363636;976.420202020202;754.8863636363636;457.17979797979797/m5KrbjM6KzfVVwPD/stephanieandnickwedding-1727-A0xVL1nO3nF15L2V.jpg",
      name: "Gordon Hung",
      title: "Chairman, CEO & Founder of R&I Management Company",
      description: `Gordon Hung is Chairman, CEO & Founder of R&I Management Company, an
      emerging Real Estate Brokerage & Venture Investment Firm. The company
      operates as a diversified holding company, with a collection of different
      operating businesses. Gordon is an entrepreneur that came from an
      extensive background of Technology Sales & Sales Management, leading teams
      at Hewlett Packard Enterprise, Tencent.`,
    },

    {
      imgString:
        "https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=1080,h=869,fit=crop,trim=26.93684210526316;280.7017543859649;233.45263157894738;262.7368421052632/m5KrbjM6KzfVVwPD/headshot-edited1-mxB8L4y2gPSDM2pJ.jpg",
      name: "Stephanie Wong",
      title: "Senior Vice President, Real Estate Operations",
      description: `Stephanie leads R&I Management’s nationwide real estate operations,
      overseeing the brokerage’s client service, finance and sales strategy as
      well as agent training and development. Since joining R&I Management in
      2024, she has been instrumental in growing the company. She has extensive
      background in real estate accounting and has held various finance roles in
      large corporations.`,
    },
  ]);
  return {
    staffCards,
  };
});

export default { useStaffsStore };
