<template>
  <section id="contact-section-id">
    <h2>Get in touch</h2>

    <form :action="`https://formsubmit.co/${receiverEmail}`" method="POST">
      <div class="form-input-wrapper">
        <div class="first-name-input input-wrap">
          <label for="fname">Name*</label>
          <input
            v-model="clientFirstName"
            type="text"
            id="fname"
            name="fname"
            placeholder="Your name"
          />
        </div>

        <div class="input-wrap">
          <label for="lname">Last name</label>
          <input
            v-model="clientLastName"
            type="text"
            id="lname"
            name="lname"
            placeholder="Your last name"
          />
        </div>

        <div class="input-wrap">
          <label for="email">Your email*</label>
          <input
            v-model="clientEmail"
            type="text"
            id="email"
            name="email"
            placeholder="Your email address"
          />
        </div>

        <div class="input-wrap">
          <label for="message">Message*</label>
          <textarea
            v-model="emailMessage"
            type="text"
            id="message"
            name="message"
            placeholder="Enter your message"
          />
        </div>
      </div>

      <button type="submit">Submit</button>
    </form>
  </section>
</template>

<script setup>
import { useSendEmailsStore } from "@/stores/sendEmail";
import { storeToRefs } from "pinia";

const sendEmailsStore = useSendEmailsStore(); // Initialize the store
const {
  emailMessage,
  clientEmail,
  clientFirstName,
  clientLastName,
  receiverEmail,
} = storeToRefs(sendEmailsStore);

// const sendEmail = () => {
//   sendEmailsStore.sendEmail(); // Call the sendEmail function from the store
// };
</script>

<style lang="scss">
@import "./ContactSection.scss";
</style>
