<template>
  <HeaderSection />
  <router-view />
</template>

<script>
import HeaderSection from "@/components/Assets/HeaderSection.vue";

export default {
  name: "App",
  components: {
    HeaderSection,
  },
};
</script>

<style src="../global.css" lang="css"></style>
