// useIntersectionObserver.js
import { ref, onMounted, onUnmounted } from "vue";

export function useIntersectionObserver(callback) {
  const element = ref(null);

  let observer;

  onMounted(() => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target);
        }
      });
    });

    if (element.value) {
      observer.observe(element.value);
    }
  });

  onUnmounted(() => {
    if (observer && element.value) {
      observer.unobserve(element.value);
    }
  });

  return {
    element,
  };
}
