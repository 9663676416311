<template>
  <section id="service-offer-id">
    <h2 class="service-heading">Services Offered</h2>
    <div class="service-offer-details-wrap">
      <div class="service-info-card">
        <img
          alt=""
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=194,h=156,fit=crop,trim=20.125;0;20.125;0/m5KrbjM6KzfVVwPD/real-estate-services-icon-AE0p3pqxgLFXpR3y.png"
          height="156"
          width="194"
          loading="lazy"
          data-qa="builder-gridelement-gridimage"
        />
        <div class="info-subheader">
          <h5 class="service-subheader">Real Estate Services</h5>
          <ul>
            <li>Property Management</li>
            <li>Community Management</li>
            <li>Brokerage</li>
          </ul>
        </div>
      </div>
      <div class="service-info-card">
        <img
          alt=""
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=194,h=167,fit=crop/m5KrbjM6KzfVVwPD/ml-technology-services-icon-A1aKVKgNp5upM1yx.png"
          height="167"
          width="194"
          loading="lazy"
          class="image__image"
          data-qa="builder-gridelement-gridimage"
        />
        <div class="info-subheader">
          <h5 class="ai-service-subheader">AI/ML Technology Services</h5>
          <ul>
            <li>Document Intelligence</li>
            <li>Virtual Agents with Generative AI</li>
            <li>Infrastructure Intelligence</li>
          </ul>
        </div>
      </div>
      <div class="service-info-card">
        <img
          alt=""
          src="https://assets.zyrosite.com/cdn-cgi/image/format=auto,w=194,h=157,fit=crop/m5KrbjM6KzfVVwPD/asset-management-services-icon-Awvr0rLyKpuepekp.png"
          height="157"
          width="194"
          loading="lazy"
          class="image__image"
          data-qa="builder-gridelement-gridimage"
        />
        <div class="info-subheader">
          <h5 class="service-subheader">Asset Management</h5>
          <ul>
            <li>Wealth Advisory</li>
            <li>Generational Transfer</li>
            <li>Additional Market Opportunity</li>
            <li>Venture Capital</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "./ServicesOfferedSection.scss";
</style>
