<template>
  <div id="staff-card-id">
    <img :src="props.imgString" />
    <p>
      <strong>{{ props.name }}</strong>
      <br />
      <i>{{ props.title }}</i>
      <br />
      <br />
      {{ props.description }}
    </p>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  imgString: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});
</script>

<style scoped lang="scss"></style>
