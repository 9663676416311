<template>
  <section id="level-investments-id">
    <h2>We cater to all levels of investment.</h2>
    <div class="card-container">
      <div
        v-for="(levelCard, index) in levelCards"
        :key="index"
        class="level-card-wrap"
      >
        <img
          alt=""
          :src="levelCard.ImgSrc"
          sizes="(min-width: 920px) 91px, calc(100vw - 0px)"
          height="91"
          width="91"
          data-qa="builder-gridelement-gridimage"
        />
        <LevelCard
          :cardHeader="levelCard.cardHeader"
          :cardBody="levelCard.cardBody"
          :cardDetails="levelCard.cardDetails"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import LevelCard from "@/components/Assets/LevelCard.vue";
import { useLevelsStore } from "@/stores/levels";
import { storeToRefs } from "pinia";

const levelsStore = useLevelsStore();
const { levelCards } = storeToRefs(levelsStore);
</script>

<style scoped lang="scss">
#level-investments-id {
  padding: 0 4%;
  text-align: center;

  h2 {
    font-size: 3rem;
    padding: 2rem 0;
    margin: 0;
  }
  background: #e3e9f0;

  .level-card-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  .card-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    font-family: DM Sans, Roboto, sans-serif !important;
  }
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-family: DM Sans, Roboto, sans-serif !important;
  flex-wrap: wrap;

  .card {
    background-color: #005b96;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.5rem;
    padding: 20px;
    width: 300px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;

    .info-header {
      font-weight: 700;
    }

    .info-body {
      line-height: 1.5rem;
    }

    .bullet-points-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      line-height: 1.5rem;
      padding: 0 1rem 0;
    }
  }
}
</style>
