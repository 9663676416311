<template>
  <section id="address-section-id">
    <p class="left-indent">
      <strong>Headquarters:</strong>
      <span
        ><br />6200 Stoneridge Mall Road. Suite 300. <br />Pleasanton, CA
        94588</span
      >
    </p>
    <p class="right-indent">
      <strong>Contact:</strong>
      <span
        ><br /><u>contact@rimanagement.co</u> <br />Phone: (925) 487-5332</span
      >
    </p>
  </section>
</template>

<script setup></script>

<style lang="scss">
@import "./AddressSection.scss";
</style>
