<template>
  <div class="card">
    <p class="info-header" style="text-align: center">
      {{ `${props.cardHeader}` }}
    </p>

    <p class="info-body">
      {{ props.cardBody }}
    </p>

    <!-- Bullet points -->
    <ul class="bullet-points-container">
      <li v-for="(detail, index) in props.cardDetails" :key="index">
        {{ detail }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  cardHeader: {
    type: String,
    required: true,
  },
  cardBody: {
    type: String,
  },
  cardDetails: {
    type: Array,
  },
});
</script>

<style lang="scss">
@import "./LevelCard.scss";
</style>
