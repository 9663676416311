import { createRouter, createWebHistory } from "vue-router";
import HomePageContent from "@/views/HomePageContent.vue";
import LeadershipPage from "@/views/LeadershipPage.vue";
import ArticlesPage from "@/views/ArticlesPage.vue";
// import About from '../views/About.vue'

const routes = [
  {
    path: "/",
    name: "homepagecontent",
    component: HomePageContent,
  },
  {
    path: "/leadership",
    name: "leadership",
    component: LeadershipPage,
  },
  {
    path: "/articles",
    name: "articles",
    component: ArticlesPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
