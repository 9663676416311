<template>
  <div
    class="approach-section-wrap"
    :class="{ 'approach-reverse-flex': reverseFlex }"
  >
    <img
      :src="props.imgString"
      alt=""
      ref="element"
      class="animate__animated"
    />
    <div class="approach-info-wrap">
      <div class="approach-label">
        {{ props.approachHeading }}
      </div>

      <p>
        {{ props.approachBody }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useIntersectionObserver } from "@/helperFunctions/IntersectionObserver";

const { element } = useIntersectionObserver((el) => {
  el.classList.add("animate__fadeInUp"); // Add your animation class
});

const props = defineProps({
  approachHeading: {
    type: String,
    required: true,
  },
  approachBody: {
    type: String,
    required: true,
  },
  imgString: {
    type: String,
  },
  reverseFlex: {
    type: Boolean,
  },
});
</script>

<style lang="scss" scoped>
.approach-section-wrap {
  display: flex;
  max-width: 1224px;
  gap: 5rem;

  .approach-info-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 4rem;
    margin: auto 0;
    .approach-label {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 2rem;
    }
    p {
      line-height: 2rem;
    }
  }
}

.approach-section-wrap.approach-reverse-flex {
  flex-direction: row-reverse;
}
</style>
